import './../scss/main.scss';

import * as data from './../data/modals_data.json';

import Mustache from 'mustache';

import gifs from '../img/*/       *.gif';
import thumbnails from '../img/*.jpg';
import photos from '../img/*/*.jpg';

let modals = data.default;
let currentOpenModalTitle = null;
window.test = photos;
//generuje kafelki z templatki Mustache
function createModal() {

    for  (let i = 0; i < modals.length; i++) {
        const element = modals[i];

        let allImagesHtml = getAllImagesForElement(element);
        
        let newModalBox = document.createElement("div");

        newModalBox.setAttribute("id", element.name);
        newModalBox.setAttribute("class", "mgModal");

        let template = document.getElementById('template').innerHTML;
        let rendered = Mustache.render(template, {
            name: element.name,
            title: element.title,
            nick: element.nick,
            thumbnail: thumbnails[element.nick],
            category: element.category,
            date: element.date,
            url: element.url,
            text: element.text,
            allImages: allImagesHtml
        });

        newModalBox.innerHTML = rendered;

        document.getElementById("contentBox").appendChild(newModalBox);
    }

}

function getAllImagesForElement(element){
    let result = "";
    let imgFolderName = element.nick;
    let catalog = photos[imgFolderName];

    let imgTemplate = document.getElementById('imgTemplate').innerHTML;
    
    for (const img in catalog) {
        if (Object.hasOwnProperty.call(catalog, img)) {
            const imgPath = catalog[img];
            
            let renderedSingleImage = Mustache.render(imgTemplate, {
                imgSrc: imgPath
            });

            result += renderedSingleImage;
        }
    }

    return result;
}

function closeModal(modal){
    modal.style.display = "none";
    modal.style.opacity = "0";
    modal.style.transition = "1s";
    document.querySelector("body").style.overflow = 'visible';
}

function openModal(modal){
    modal.style.display = "block";
    modal.style.opacity = "1";
    modal.style.transition = "1s";
    document.querySelector("body").style.overflow = 'hidden';
}

//funkcja otwierająca i zamykajaca modal
function modalClicks() {

    for (let i = 0; i < modals.length; i++) {
        let modal = document.getElementById(modals[i].nick);
        let btn = document.getElementById(modals[i].name);
        let xclose = document.getElementsByClassName("close")[i];

        btn.onclick = function () {
            openModal(modal);
            registerOpenedModal(modal.id);
            currentOpenModalTitle = modal.id;
        }
        xclose.onclick = function (event) {
            closeModal(modal);
            registerModalsClosed();
            currentOpenModalTitle = null;
            event.stopPropagation();
        }
        
        window.addEventListener("click", function (event) {
            if (event.target == modal) {
                closeModal(modal);
                currentOpenModalTitle = null;
                registerModalsClosed();
            }
        });
    }
}

function registerOpenedModal(modalTitle){
    if(window.history.state.noBackExitsApp){
        window.history.pushState({ modalTitle: modalTitle }, modalTitle);
    }
}

function registerModalsClosed(){
    if(currentOpenModalTitle == null){
        window.history.back();
    }
}

function addInitialHistoryState(){
    window.history.pushState({ noBackExitsApp: true }, '')
}


function closeAnyModal(){
    var allModals = document.querySelectorAll(".modal");
    for (const modal of allModals) {
        if(modal.style.display == "block"){
            closeModal(modal);
        }
    }
}


function registerBackButtonHandler(){
    window.addEventListener("popstate", function(e){
        if(!e.state)
            return;

        if(e.state.noBackExitsApp){
            closeAnyModal();
            return;
        }
    });
}

//funkcje załadują się po wczytaniu htmla
document.body.onload = function () {
    createModal();
    modalClicks();
    addInitialHistoryState();
    registerBackButtonHandler();
}